.sider {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
}

.logo {
  color: #fff;
  padding: 12px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.content {
  padding: 12px;
}

.contentLayout {
  margin-left: 200px;
}

.contentHeader {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px !important;
}
